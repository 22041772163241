import React, { useState, useEffect } from 'react';
import {
  ResponsiveContainer,
  Cell,
  Tooltip,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Brush,
  BarChart,
  Bar,
  LineChart,
  Line,
  ComposedChart,
} from 'recharts';
import ChartInfo from '../Popover/ChartInfo';
import Oval from '../img/Oval.svg';

const CloseRate = ({ allDeals, loading }) => {
  const [selectedCloseRate, setSelectedCloseRate] = useState('Quarter');
  const [yearDeals, setYearDeals] = useState([]);
  const [monthkDeals, setMonthDeals] = useState([]);
  const [weekDeals, setWeekDeals] = useState([]);
  const [quarterDeals, setQuarterDeals] = useState([]);
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const screenResolution = screenSize.width;

  const currentDate = new Date();

  //QUARTER
  useEffect(() => {
    // Calculate the start and end dates for the last 3 months
    const past3MonthsStartDate = new Date();
    past3MonthsStartDate.setMonth(currentDate.getMonth() - 3);

    const currentDayEndDate = currentDate;

    // Group data by months within the last 3 months
    const monthGroups = {};
    const totalMonths =
      (currentDayEndDate.getFullYear() - past3MonthsStartDate.getFullYear()) *
        12 +
      currentDayEndDate.getMonth() -
      past3MonthsStartDate.getMonth() +
      1;

    for (let monthNumber = 1; monthNumber <= totalMonths; monthNumber++) {
      monthGroups[monthNumber] = [];
    }

    const filteredDataThreeMonths = allDeals.filter((item) => {
      const closeDate = new Date(item.CloseDate);
      return (
        closeDate >= past3MonthsStartDate && closeDate <= currentDayEndDate
      );
    });

    filteredDataThreeMonths.forEach((item) => {
      const closeDate = new Date(item.CloseDate);
      const monthNumber =
        (closeDate.getFullYear() - past3MonthsStartDate.getFullYear()) * 12 +
        closeDate.getMonth() -
        past3MonthsStartDate.getMonth() +
        1;

      if (!monthGroups[monthNumber]) {
        monthGroups[monthNumber] = [];
      }

      monthGroups[monthNumber].push(item);
    });

    // Calculate the percentage of "Closed Won" objects for each month
    const result = Object.keys(monthGroups).map((monthNumber) => {
      const totalObjects = monthGroups[monthNumber].length;
      const closedWonObjects = monthGroups[monthNumber].filter(
        (item) => item.StageName === 'Closed Won'
      ).length;
      const percentage =
        totalObjects === 0
          ? 0
          : ((closedWonObjects / totalObjects) * 100).toFixed(2);

      // Get the month name with the first letter capitalized
      const monthName = new Date(
        past3MonthsStartDate.getFullYear(),
        past3MonthsStartDate.getMonth() + parseInt(monthNumber) - 1
      )
        .toLocaleDateString('en-US', { month: 'long' })
        .replace(/^\w/, (c) => c.toUpperCase());

      return { Month: monthName, Percentage: parseFloat(percentage) };
    });
    // console.log(result, 'QUARTER RESULT');
    setQuarterDeals(result);
  }, [allDeals]);

  //WEEK
  useEffect(() => {
    // Calculate the start date of the past week (7 days ago)
    const pastWeekStartDate = new Date();
    pastWeekStartDate.setDate(currentDate.getDate() - 6);
    pastWeekStartDate.setHours(0, 0, 0, 0); // Set hours and minutes to 00:00
    pastWeekStartDate.setDate(currentDate.getDate() - 6);

    const resultWeek = [];
    for (
      let day = pastWeekStartDate.getDate();
      day < currentDate.getDate();
      day++
    ) {
      const currentDateClone = new Date(currentDate);
      currentDateClone.setDate(day);

      // Filter objects with CloseDates matching the current day
      const filteredData = allDeals.filter((item) => {
        const closeDate = new Date(item.CloseDate);
        return (
          closeDate.getFullYear() === currentDateClone.getFullYear() &&
          closeDate.getMonth() === currentDateClone.getMonth() &&
          closeDate.getDate() === currentDateClone.getDate()
        );
      });

      const totalObjects = filteredData.length;
      const closedWonObjects = filteredData.filter(
        (item) => item.StageName === 'Closed Won'
      ).length;
      const percentage = (closedWonObjects / totalObjects) * 100 || 0;

      const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      const dayName = dayNames[currentDateClone.getDay()];

      resultWeek.push({
        Day: dayName,
        Percentage: percentage.toFixed(2),
      });
    }

    setWeekDeals(resultWeek);
  }, [allDeals]);

  //MONTH
  useEffect(() => {
    // Calculate the start and end dates for the last 30 days
    const past30DaysStartDate = new Date();
    past30DaysStartDate.setDate(currentDate.getDate() - 30);

    const currentDayEndDate = currentDate;

    // Group data by weeks within the last 30 days
    const weekGroups = {};
    const totalWeeks = Math.ceil(
      (currentDayEndDate - past30DaysStartDate) / (7 * 24 * 60 * 60 * 1000)
    );

    for (let weekNumber = 1; weekNumber <= totalWeeks; weekNumber++) {
      weekGroups[weekNumber] = [];
    }

    const filteredDataMonth = allDeals.filter((item) => {
      const closeDate = new Date(item.CloseDate);
      return closeDate >= past30DaysStartDate && closeDate <= currentDayEndDate;
    });

    filteredDataMonth.forEach((item) => {
      const closeDate = new Date(item.CloseDate);
      const weekNumber =
        Math.ceil(
          (closeDate - past30DaysStartDate) / (7 * 24 * 60 * 60 * 1000)
        ) + 1;

      if (!weekGroups[weekNumber]) {
        weekGroups[weekNumber] = [];
      }

      weekGroups[weekNumber].push(item);
    });

    // Calculate the percentage of "Closed Won" objects for each week
    const result = Object.keys(weekGroups).map((weekNumber) => {
      const totalObjects = weekGroups[weekNumber].length;
      const closedWonObjects = weekGroups[weekNumber].filter(
        (item) => item.StageName === 'Closed Won'
      ).length;
      const percentage =
        totalObjects === 0
          ? 0
          : ((closedWonObjects / totalObjects) * 100).toFixed(2);
      return { Week: `Week ${weekNumber}`, Percentage: parseFloat(percentage) };
    });

    setMonthDeals(result);
  }, [allDeals]);

  //YEAR
  useEffect(() => {
    const filteredData = allDeals.filter((item) => {
      const closeDate = new Date(item.CloseDate);
      const monthsDifference =
        (currentDate.getFullYear() - closeDate.getFullYear()) * 12 +
        currentDate.getMonth() -
        closeDate.getMonth();
      return monthsDifference >= 0 && monthsDifference <= 12;
    });

    const quarterGroups = {
      Q1: [],
      Q2: [],
      Q3: [],
      Q4: [],
    };

    filteredData.forEach((item) => {
      const closeDate = new Date(item.CloseDate);
      const monthsDifference =
        (currentDate.getFullYear() - closeDate.getFullYear()) * 12 +
        currentDate.getMonth() -
        closeDate.getMonth();

      if (monthsDifference >= 0 && monthsDifference <= 2) {
        quarterGroups.Q4.push(item);
      } else if (monthsDifference >= 3 && monthsDifference <= 5) {
        quarterGroups.Q3.push(item);
      } else if (monthsDifference >= 6 && monthsDifference <= 8) {
        quarterGroups.Q2.push(item);
      } else if (monthsDifference >= 9 && monthsDifference <= 11) {
        quarterGroups.Q1.push(item);
      }
    });

    const result = Object.keys(quarterGroups).map((quarter) => {
      const totalObjects = quarterGroups[quarter].length;
      const closedWonObjects = quarterGroups[quarter].filter(
        (item) => item.StageName === 'Closed Won'
      ).length;
      const percentage =
        totalObjects > 0
          ? ((closedWonObjects / totalObjects) * 100).toFixed(2)
          : '0.00';
      return { Quarter: quarter, Percentage: parseFloat(percentage) };
    });
    setYearDeals(result);
  }, [allDeals]);

  const categorizedData = allDeals.reduce((result, deal) => {
    const closeDate = new Date(deal.CloseDate);
    const quarter = Math.floor(closeDate.getMonth() / 3) + 1; // 1 for Q1, 2 for Q2, etc.
    const year = closeDate.getFullYear();
    const key = `Q${quarter}-${year.toString().slice(2)}`;

    if (!result[key]) {
      result[key] = { name: key, ClosedWon: 0, Others: 0 };
    }

    if (deal.StageName === 'Closed Won') {
      result[key].ClosedWon++;
    } else {
      result[key].Others++;
    }

    return result;
  }, {});

  const categorizedDataYear = allDeals.reduce((result, deal) => {
    const closeDate = new Date(deal.CloseDate);
    const year = closeDate.getFullYear();
    const key = year.toString();

    if (!result[key]) {
      result[key] = { name: key, ClosedWon: 0, Others: 0 };
    }

    if (deal.StageName === 'Closed Won') {
      result[key].ClosedWon++;
    } else {
      result[key].Others++;
    }

    return result;
  }, {});
  // console.log(categorizedData, 'CATEGORIZED DATA QUARTER');
  // console.log(categorizedDataYear, 'CATEGORIZED DATA YEAR');

  // Calculate the maximum total opportunities
  let maxTotalOpportunities = 0;
  let maxTotalOpportunitiesYear = 0;

  Object.values(categorizedData).forEach((item) => {
    const totalOpportunities = item.ClosedWon + item.Others;
    if (totalOpportunities > maxTotalOpportunities) {
      maxTotalOpportunities = totalOpportunities;
    }
  });

  Object.values(categorizedDataYear).forEach((item) => {
    const totalOpportunities = item.ClosedWon + item.Others;
    if (totalOpportunities > maxTotalOpportunitiesYear) {
      maxTotalOpportunitiesYear = totalOpportunities;
    }
  });

  // Calculate the CloseRate for each object based on the maxTotalOpportunities
  Object.values(categorizedData).forEach((item) => {
    item.CloseRate =
      maxTotalOpportunities === 0
        ? 0
        : (item.ClosedWon / (item.ClosedWon + item.Others)) *
          maxTotalOpportunities;
  });

  Object.values(categorizedDataYear).forEach((item) => {
    item.CloseRate =
      maxTotalOpportunitiesYear === 0
        ? 0
        : (item.ClosedWon / (item.ClosedWon + item.Others)) *
          maxTotalOpportunitiesYear;
  });

  const chartDataYear = Object.values(categorizedDataYear);

  const chartData = Object.values(categorizedData).sort((a, b) => {
    const [quarterA, yearA] = a.name.split('-');
    const [quarterB, yearB] = b.name.split('-');

    // Convert quarter strings to numbers for comparison
    const quarterOrder = { Q1: 1, Q2: 2, Q3: 3, Q4: 4 };

    return yearA - yearB || quarterOrder[quarterA] - quarterOrder[quarterB];
  });

  const CustomTooltip = ({
    active,
    payload,
    label,
    maxTotalOpportunitiesYear,
    maxTotalOpportunities1,
  }) => {
    if (active && payload && payload.length) {
      return (
        <div className='bg-white border-2 rounded-lg border-gray-300 p-4'>
          <p className=''>{`${label}`}</p>
          {payload.map((pld, index) => (
            <div className='py-1 flex flex-col'>
              {index == 0 && (
                <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block my-2'></span>
              )}
              {selectedCloseRate === 'Quarter' ? (
                <div style={{ color: pld.stroke || pld.fill }}>
                  {pld.name === 'CloseRate' ? (
                    <>{`${pld.name}: %${Math.floor(
                      (pld.value / maxTotalOpportunities1) * 100
                    )}`}</>
                  ) : (
                    <> {`${pld.name}: ${pld.value}`}</>
                  )}
                  {/* {`${pld.name}: ${pld.value}`} */}
                </div>
              ) : (
                <div style={{ color: pld.stroke || pld.fill }}>
                  {pld.name === 'CloseRate' ? (
                    <>{`${pld.name}: %${Math.floor(
                      (pld.value / maxTotalOpportunitiesYear) * 100
                    )}`}</>
                  ) : (
                    <> {`${pld.name}: ${pld.value}`}</>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      );
    }

    return null;
  };
  const CustomLegend = ({ payload }) => {
    return (
      <div className='flex items-center 2xl:gap-3'>
        {payload.map((entry, index) => (
          <div key={`legend-${index}`} className='mr-4 flex items-center'>
            {entry.type === 'line' ? (
              <div>
                <svg
                  className='recharts-surface'
                  width='14'
                  height='14'
                  viewBox='0 0 32 32'
                  style={{
                    display: 'inline-block',
                    verticalAlign: 'middle',
                    marginRight: '4px',
                  }}
                >
                  <title></title>
                  <desc></desc>
                  <path
                    strokeWidth='4'
                    fill='none'
                    stroke='#E55C42'
                    d='M0,16h10.666666666666666
    A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
    H32M21.333333333333332,16
    A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16'
                    className='recharts-legend-icon'
                  ></path>
                </svg>
              </div>
            ) : (
              <div
                className='w-[14px] h-[10.5px] mr-2'
                style={{ backgroundColor: entry.color }}
              ></div>
            )}
            <span style={{ color: entry.color }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };
  return (
    <div className=''>
      <div className=''>
        <div className='flex items-center'>
          <h1 className='text-[22px] mr-1 font-semibold text-left'>
            Close Rate per {selectedCloseRate}
          </h1>
          <ChartInfo
            text={
              'Shows your Close Rate between your total deals and your deals successfuly closed, you can adjust between the Quarter and Year timeframes.'
            }
          />
        </div>
        <span className='form-divider h-1 mr-0 lg:mr-6 2xl:mr-3 hidden md:block mt-2 mb-7'></span>
      </div>
      {loading === true ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          <img src={Oval}></img>
        </div>
      ) : loading === false && allDeals.length <= 0 ? (
        <div className='flex items-center justify-center bg-[#F6F6F6] h-[450px]'>
          Register your first deal to view this chart
        </div>
      ) : (
        <div className='rounded-lg  p-3 bg-[#F6F6F6] pt-2'>
          <div className='flex justify-end'>
            <div className='w-full flex mt-3 ml-16 justify-start md:ml-0 md:justify-end gap-4 mr-7 z-10'>
              <button
                onClick={() => setSelectedCloseRate('Quarter')}
                className={`px-2 border border-transparent rounded-xl hover:border  hover:border-[#3585F9] ${
                  selectedCloseRate === 'Quarter' && 'bg-[#3585F9] text-white'
                }`}
              >
                Quarter
              </button>

              <button
                onClick={() => setSelectedCloseRate('Year')}
                className={`px-2 border border-transparent rounded-xl hover:border  hover:border-[#3585F9] ${
                  selectedCloseRate === 'Year' && 'bg-[#3585F9] text-white'
                }`}
              >
                Year
              </button>
            </div>
          </div>
          <div className='justify-start flex items-center m-0 p-0'>
            <ResponsiveContainer width='100%' height={382}>
              <ComposedChart
                data={
                  selectedCloseRate === 'Quarter' ? chartData : chartDataYear
                }
                margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='name' />
                <YAxis />
                <Tooltip
                  content={
                    <CustomTooltip
                      maxTotalOpportunitiesYear={maxTotalOpportunitiesYear}
                      maxTotalOpportunities1={maxTotalOpportunities}
                    />
                  }
                />
                <Legend
                  verticalAlign='top'
                  align='left'
                  wrapperStyle={
                    screenResolution < 1280 || screenResolution > 1574
                      ? { marginLeft: 40, paddingBottom: 10, top: -28 }
                      : { marginLeft: 45, paddingBottom: 10, top: 10 }
                  }
                  content={<CustomLegend />}
                />
                <Brush dataKey='name' height={30} stroke='#9fc3fa' />

                <Bar dataKey='Others' stackId='a' fill='#3585F9'>
                  {selectedCloseRate === 'Quarter'
                    ? chartData.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          radius={
                            !entry.ClosedWon ? [10, 10, 0, 0] : [0, 0, 0, 0]
                          }
                        />
                      ))
                    : chartDataYear.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          radius={
                            !entry.ClosedWon ? [10, 10, 0, 0] : [0, 0, 0, 0]
                          }
                        />
                      ))}
                </Bar>
                <Bar dataKey='ClosedWon' stackId='a' fill='#6BCEBA'>
                  {selectedCloseRate === 'Quarter'
                    ? chartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} radius={[10, 10, 0, 0]} />
                      ))
                    : chartDataYear.map((entry, index) => (
                        <Cell key={`cell-${index}`} radius={[10, 10, 0, 0]} />
                      ))}
                </Bar>
                <Line
                  type='monotone'
                  dataKey='CloseRate'
                  stroke='#E55C42'
                  strokeWidth={3}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </div>
        </div>
      )}
    </div>
  );
};

export default CloseRate;
