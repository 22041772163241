import React, { useState, useEffect } from 'react';
import Plus from '../components/img/Plus.svg';
import BlueInfo from '../components/img/BlueInfo.svg';
import Search from '../components/img/Search.svg';
import ArrowToRight from '../components/img/ArrowToRight';

import { Link, useNavigate, useLocation } from 'react-router-dom';
import 'react-circular-progressbar/dist/styles.css';
import '../index.css';
import RegisteredDealsTable from './RegisteredDealsTable';
import MyCustomersTable from './MyCustomersTable';
import MobileLevels from '../components/MobileLevels';
import MobileTables from '../components/MobileTables';
import PlanBenefitsModal from '../components/Modals/PlanBenefitsModal';
import {
  useGetDealsMutation,
  useGetCustomersMutation,
  useGetExpiredCustomersMutation,
  useGetActiveSubscriptionsMutation,
} from '../redux/features/user/dealsApiSlice';
import useAuth from '../hooks/useAuth';
import {
  useGetPartnerByIdMutation,
  useGetPartnerDetailsMutation,
} from '../redux/features/user/partnersApiSlice';
import PartnerProgramTermModal from '../components/Modals/PartnerProgramTerm';
import CloseRate from '../components/AnalyticsPanel/CloseRate';
import axios from 'axios';
import BlogPostIcon from '../components/img/BlogPostIcon.svg';
import { Formik, Form, Field } from 'formik';

const Dashboard = () => {
  const [deals, SetDeals] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [expiredCustomers, SetExpiredCustomers] = useState([]);
  const [callLoadingDeals, setCallLoadingDeals] = useState(true);
  const [callLoadingCustomers, setCallLoadingCustomers] = useState(true);
  const [callLoadingExpiredCustomers, setCallLoadingExpiredCustomers] =
    useState(true);
  const [getDeals] = useGetDealsMutation();
  const [getCustomers] = useGetCustomersMutation();
  const [getExpiredCustomers] = useGetExpiredCustomersMutation();
  const [getActiveSubscriptions] = useGetActiveSubscriptionsMutation();
  const [partnerData, setPartnerData] = useState(null);
  const [partnerDetails, setPartnerDetails] = useState(null);
  const [postsData, setPostsData] = useState([]);
  const [activeSubscriptions, setActiveSubscriptions] = useState([]);
  const [callLoadingActiveSubscriptions, setCallLoadingActiveSubscriptions] =
    useState(true);

  const userinfo = useAuth();
  const [getPartner] = useGetPartnerByIdMutation();
  const [getPartnerDetails] = useGetPartnerDetailsMutation();

  const [sort, setSort] = useState('Partner_Client__c');
  const [orientation, setOrientation] = useState('ascending');

  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
    };
    getPartnerData();
  }, []);

  useEffect(() => {
    const getPostData = async () => {
      const postData = await axios.get(
        'https://wazuh.com/wp-json/wp/v2/posts?_fields=yoast_head_json.schema.%40graph,id,title,link&per_page=2'
      );
      const posts = postData.data.map((post) => {
        const graph = post.yoast_head_json?.schema?.['@graph'];
        const articleSection =
          graph && graph.length > 0 ? graph[0].articleSection[0] : null;
        const cleanedData = post.title.rendered.replace(/&#8211;|–/g, '-');
        return {
          id: post.id,
          title: cleanedData,
          link: post.link,
          articleSection: articleSection,
        };
      });

      setPostsData(posts);
    };
    getPostData();
  }, []);

  useEffect(() => {
    const getPartnerDetailsFunction = async () => {
      if (partnerData && partnerData.length > 0) {
        const partnerInfo = await getPartnerDetails(
          partnerData[0].salesforceId
        ).unwrap();
        setPartnerDetails(partnerInfo);
      }
    };
    getPartnerDetailsFunction();
  }, [partnerData]);
  // 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
  // useEffect(() => {
  //   const getPh = async () => {
  //     const Ph = await axios.get(
  //       `https://api.github.com/repos/wazuh/wazuh-operations/contents/current_customers/ArmoToolLimited/PH.md`,
  //       {
  //         headers: {
  //           Authorization: 'token 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',
  //         },
  //       }
  //     );
  //     await setPh(Ph);
  //     const decoded_content = atob(Ph.data.content);
  //     const lines = decoded_content.split('\n');
  //     const last_line = lines[lines.length - 2];
  //     const last_line_split = last_line.split('|');
  //     console.log(JSON.parse(last_line_split[4]), 'PHH');
  //   };
  //   getPh();
  // }, []);

  useEffect(() => {
    const getDealsData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getDeals(partnerData[0].salesforceId).unwrap();
        SetDeals(result);
        await setCallLoadingDeals(false);
      }
    };
    getDealsData();
  }, [partnerData]);

  useEffect(() => {
    const getCustomersData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getCustomers(partnerData[0].salesforceId).unwrap();
        setCustomers(result);
        await setCallLoadingCustomers(false);
      }
    };
    getCustomersData();
  }, [partnerData]);

  useEffect(() => {
    const getExpiredCustomersData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getExpiredCustomers(
          partnerData[0].salesforceId
        ).unwrap();
        SetExpiredCustomers(result);
        await setCallLoadingExpiredCustomers(false);
      }
    };
    getExpiredCustomersData();
  }, [partnerData]);

  useEffect(() => {
    const getActiveSubscriptionsData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getActiveSubscriptions(
          partnerData[0].salesforceId
        ).unwrap();
        setActiveSubscriptions(result);
        console.log(result, 'ACTIVE SUBSCRIPTIONS');
        await setCallLoadingActiveSubscriptions(false);
      }
    };
    getActiveSubscriptionsData();
  }, [partnerData]);

  const CloudTypes = [
    'Customer New Cloud',
    'Customer Renewal Cloud',
    'Customer Upgrade Cloud',
    'Customer Renewal/Upgrade Cloud',
    'Customer Renewal/Downgrade Cloud',
    'Partner Customer New Cloud',
    'Partner Customer Renewal Cloud',
    'Partner Customer Renewal/Upgrade Cloud',
    'Partner Customer Renewal/Downgrade Cloud',
  ];

  const PremisesTypes = [
    'Customer New On-premise',
    'Customer Renewal On-premise',
    'Customer Renewal/Expansion On-premise',
    'Customer Renewal/Reduction On-premise',
    'Customer Expansion On-premise',
    'Partner Customer New On premises',
    'Partner Customer Renewal On premises',
    'Partner Customer Renewal/ Upgrade On premises',
  ];

  const newArrayDeals = deals
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    })
    .sort((a, b) => a.Partner_Client__c?.localeCompare(b.Partner_Client__c))
    .slice(0, 6);

  function filterAndSortArray(data, sortByProperty, sortDirection) {
    // Check if sortDirection is valid
    if (sortDirection !== 'ascending' && sortDirection !== 'descending') {
      throw new Error(
        'Invalid sortDirection. Use "ascending" or "descending".'
      );
    }

    // Clone the data array to avoid modifying the original array
    const clonedData = [...data];

    // Sort the array based on the sortByProperty
    clonedData.sort((a, b) => {
      const aValue = a[sortByProperty];
      const bValue = b[sortByProperty];

      // Compare the values based on the sortDirection
      if (sortDirection === 'ascending') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return clonedData;
  }

  const arrayWithStageDeals = newArrayDeals.map((obj) => {
    if (obj.StageName === 'Closed Lost') {
      return { ...obj, StageName: 'Closed lost' };
    } else if (obj.StageName === 'Closed Disqualified') {
      return { ...obj, StageName: 'Not approved' };
    } else if (obj.StageName === 'MQL - Discover') {
      return { ...obj, StageName: 'Pending approval' };
    } else return { ...obj, StageName: 'Approved' };
  });

  const newArrayCustomers = customers
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    })
    .slice(0, 6);

  const newArrayExpiredCustomers = expiredCustomers
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    })
    .slice(0, 6);

  const search = (data) => {
    return data.filter(
      (item) =>
        item.Name.toLowerCase().includes(query.toLowerCase()) ||
        item.Type.toLowerCase().includes(query.toLowerCase()) ||
        item.StageName.toLowerCase().includes(query.toLowerCase()) ||
        item.CloseDate.toLowerCase().includes(query.toLowerCase())
    );
  };

  const search2 = (data) => {
    return data.filter(
      (item) =>
        item.Name.toLowerCase().includes(query.toLowerCase()) ||
        item.Type.toLowerCase().includes(query.toLowerCase()) ||
        item.Start_Date__c.toLowerCase().includes(query.toLowerCase()) ||
        item.CloseDate.toLowerCase().includes(query.toLowerCase())
    );
  };

  const [selectTable, setSelectTable] = useState('RegisteredDeals');
  const [query, setQuery] = useState('');

  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener('resize', updateDimension);

    return () => {
      window.removeEventListener('resize', updateDimension);
    };
  }, [screenSize]);

  const screenResolution = screenSize.height;

  const sliceOnScreenHeight = (data) => {
    if (screenResolution < 650) {
      return data.slice(0, 2);
    } else if (screenResolution < 700) {
      return data.slice(0, 3);
    } else if (screenResolution < 800) {
      return data.slice(0, 4);
    } else if (screenResolution < 900) {
      return data.slice(0, 5);
    } else {
      return data;
    }
  };

  const filteredDeals = deals
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    });

  const filteredCustomers = customers
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    });

  // const allDeals = [...allExpiredcustomers, ...customers, ...deals];
  const allDeals = [...filteredCustomers, ...filteredDeals];
  const allDealsLoading =
    callLoadingDeals === true && callLoadingCustomers === true;

  const location = useLocation();
  const navigate = useNavigate();
  const searchName = location.pathname.split('/')[2];

  const handleSearchSubmit = (values) => {
    navigate(`/search/${values.query}`);
  };

  return (
    <div>
      <PartnerProgramTermModal />
      <div className='flex justify-between dashboard-background rounded-2xl m-7'>
        <div className='m-8 2xl:mx-[55px] 2xl:my-[40px] max-sm:w-full '>
          <div className='max-sm:flex justify-between'>
            <h1 className=' text-xl  sm:text-2xl md:text-3xl font-semibold text-white'>
              Welcome {userinfo.username}!
            </h1>
          </div>
          {/* <h2 className='hidden sm:block text-2xl font-semibold mt-2 text-white'>
            {partnerDetails ? partnerDetails[0].Account_Type__c : '-'}
          </h2> */}
          {partnerDetails ? (
            <PlanBenefitsModal
              plan={partnerDetails[0]?.Account_Type__c}
              discount={partnerDetails[0]?.Partner_Current_Discount__c}
            />
          ) : (
            <PlanBenefitsModal discount={0} />
          )}

          {partnerDetails ? (
            <MobileLevels
              discount={partnerDetails[0]?.Partner_Current_Discount__c}
              plan={partnerDetails[0]?.Account_Type__c}
            />
          ) : (
            <MobileLevels discount={0} plan={'-'} />
          )}
        </div>
      </div>
      <div className='mx-12 2xl:ml-[70px]'>
        <div className='ml-3 mr-4 grid grid-cols-3'>
          <div className='flex col-span-2'>
            <Link to='/deal-registration'>
              <button className='h-full bg-yellow-gradient btn text-[#292929]  flex items-center px-14 py-4 rounded-lg'>
                <img alt='plus sign' className='xl:pr-2' src={Plus}></img>
                <p className='hidden xl:block'>Deal registration</p>
              </button>
            </Link>

            <Formik initialValues={{ query: '' }} onSubmit={handleSearchSubmit}>
              {({ handleChange }) => (
                <Form className='flex flex-1 mr-1 relative'>
                  <button
                    type='submit'
                    className='inline-flex hover:cursor-pointer absolute right-4 top-[21px] items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md border-r-0'
                  >
                    <img alt='search' src={Search} />
                  </button>
                  <Field
                    type='text'
                    name='query'
                    id='website-admin'
                    className='rounded-md ml-9 pl-4 bg-gray-50 border text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0 w-full text-sm border-gray-300'
                    placeholder='Search for deals'
                    onChange={handleChange}
                  />
                </Form>
              )}
            </Formik>
          </div>
          <div className='flex items-center justify-around  col-span-1'>
            <div className='bg-[rgba(246,246,246,0.8)] w-full justify-between flex ml-4 pl-5 py-4 rounded-lg pr-5'>
              <p className='text-xl font-semibold'>Active subscriptions:</p>
              <p className='text-xl font-semibold text-blue-500 justify-end'>
                {callLoadingActiveSubscriptions === false
                  ? activeSubscriptions.length
                  : '-'}
              </p>
            </div>
          </div>
        </div>

        <div className='grid grid-cols-3 gap-5 mt-8 ml-3 mr-4 mb-4'>
          <div className='col-span-2 bg-[#F6F6F6] p-4 rounded-lg'>
            <CloseRate allDeals={allDeals} loading={allDealsLoading} />
          </div>
          <div className='col-span-1 flex flex-col bg-[#F6F6F6] h-full p-4 rounded-lg overflow-hidden'>
            <div className='text-xl font-semibold'>Latest posts</div>
            <span className='form-divider w-full h-1 my-3'></span>
            <div className='flex gap-5 flex-col justify-between h-full'>
              {postsData.map((post, index) => (
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  href={post.link}
                  key={index}
                  className={`${
                    index % 2 === 0
                      ? 'bg-[rgb(235,235,235)]'
                      : 'bg-[rgb(61,130,241)]'
                  } rounded-lg p-2 transform hover:scale-105 transition duration-300 ease-out hover:cursor-pointer  h-1/2`}
                >
                  <span
                    className={`${
                      index % 2 === 0
                        ? 'bg-[rgb(61,130,241)]'
                        : 'bg-[rgb(112,164,248)]'
                    } rounded-tl-xl rounded-br-xl text-sm px-2 py-1 relative -top-2 -left-2 text-white`}
                  >
                    {post.articleSection}
                  </span>
                  <div className='flex justify-around items-center mt-7 '>
                    <div
                      className={`${
                        index % 2 === 0 ? '' : 'text-white'
                      } w-[300px] text-lg h-full max-h-[120px] multi-line-ellipsis`}
                    >
                      {post.title}
                    </div>
                    <div className='items-center justify-center flex relative'>
                      <div className='bg-white w-24 h-24 flex items-center justify-center p-2 rounded-full'>
                        <img
                          className='w-14 h-12'
                          src={BlogPostIcon}
                          alt='Blog post icon'
                        />
                      </div>
                    </div>
                  </div>
                </a>
              ))}
            </div>

            {/* <h1 className='font-bold text-2xl'>Latest blogposts</h1>
            <div className='w-full grid grid-cols-1'>
              <span className='form-divider w-full h-1 my-3'></span>
            </div>
            <div className='mx-4 flex flex-col justify-between h-full'>
              {postsData.map((post, index) => (
                <div
                  key={index}
                  className={`${
                    index % 2 === 0
                      ? 'bg-[rgb(235,235,235)]'
                      : 'bg-[rgb(61,130,241)]'
                  } rounded-lg p-2 flex-grow mb-8`}
                >
                  <span
                    className={`${
                      index % 2 === 0
                        ? 'bg-[rgb(61,130,241)]'
                        : 'bg-[rgb(112,164,248)]'
                    } rounded-tl-xl rounded-br-xl text-sm px-2 py-1 relative -top-2 -left-2 text-white`}
                  >
                    {post.articleSection}
                  </span>
                  <div className='grid grid-cols-3'>
                    <h1
                      className={`${
                        index % 2 === 0 ? 'text-lg' : 'text-white'
                      } col-span-2 p-4 font-manrope xl:text-lg`}
                    >
                      {post.title}
                    </h1>
                    <div className='items-center justify-center flex bottom-3 relative'>
                      <div className='bg-white w-24 h-24 flex items-center justify-center p-2 rounded-full'>
                        <img
                          className='w-14 h-12'
                          src={BlogPostIcon}
                          alt='Blog post icon'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
