import React, { useState, useEffect } from 'react';
import BlueInfo from '../components/img/BlueInfo.svg';
import Search from '../components/img/Search.svg';
import Plus from '../components/img/Plus.svg';
import Export from '../components/img/Export';
import MyCustomersTable from './MyCustomersTable';
import { Link } from 'react-router-dom';
import MobileMyCustomers from '../components/MobileMyCustomers';
import useAuth from '../hooks/useAuth';
import { useGetExpiredCustomersMutation } from '../redux/features/user/dealsApiSlice';
import { useGetPartnerByIdMutation } from '../redux/features/user/partnersApiSlice';
import { downloadExcel } from 'react-export-table-to-excel';

const ExpiredCustomers = () => {
  const [customers, SetCustomers] = useState([]);
  const [query, setQuery] = useState('');
  const [callLoading, setCallLoading] = useState(true);
  const [partnerData, setPartnerData] = useState(null);
  const [getCustomers] = useGetExpiredCustomersMutation();
  const [getPartner] = useGetPartnerByIdMutation();
  const [sort, setSort] = useState('Partner_Client__c');
  const [orientation, setOrientation] = useState('ascending');

  const userinfo = useAuth();

  useEffect(() => {
    const getPartnerData = async () => {
      const partnerInfo = await getPartner(userinfo.partnerId).unwrap();
      setPartnerData(partnerInfo);
    };
    getPartnerData();
  }, []);

  useEffect(() => {
    const getCustomersData = async () => {
      if (partnerData && partnerData.length > 0) {
        const result = await getCustomers(partnerData[0].salesforceId).unwrap();
        SetCustomers(result);
        await setCallLoading(false);
      }
    };
    getCustomersData();
  }, [partnerData]);

  const CloudTypes = [
    'Customer New Cloud',
    'Customer Renewal Cloud',
    'Customer Upgrade Cloud',
    'Customer Renewal/Upgrade Cloud',
    'Customer Renewal/Downgrade Cloud',
    'Partner Customer New Cloud',
    'Partner Customer Renewal Cloud',
    'Partner Customer Renewal/Upgrade Cloud',
    'Partner Customer Renewal/Downgrade Cloud',
  ];

  const PremisesTypes = [
    'Customer New On-premise',
    'Customer Renewal On-premise',
    'Customer Renewal/Expansion On-premise',
    'Customer Renewal/Reduction On-premise',
    'Customer Expansion On-premise',
    'Partner Customer New On premises',
    'Partner Customer Renewal On premises',
    'Partner Customer Renewal/ Upgrade On premises',
  ];

  const newArray = customers
    .filter(
      (obj) => CloudTypes.includes(obj.Type) || PremisesTypes.includes(obj.Type)
    )
    .map((obj) => {
      return {
        ...obj,
        Type: CloudTypes.includes(obj.Type) ? 'Cloud' : 'On premises',
      };
    });

  // const today = new Date();
  // const filteredArray = newArray.filter((obj) => {
  //   const expirationDate = new Date(obj.Contract_Expiration_Date__c);
  //   return expirationDate < today;
  // });

  // console.log(filteredArray, 'filteredArray');

  const search2 = (data) => {
    return data.filter(
      (item) =>
        item.Partner_Client__c.toLowerCase().includes(query.toLowerCase()) ||
        item.Type.toLowerCase().includes(query.toLowerCase()) ||
        item.Contract_Expiration_Date__c?.toLowerCase().includes(
          query.toLowerCase()
        ) ||
        item.Amount.toString().includes(query)
    );
  };

  const header = ['Company', 'Service', 'Expiration Date', 'Amount'];
  const body = filterAndSortArray(search2(newArray), sort, orientation).map(
    (data) => [
      data.Partner_Client__c,
      data.Type,
      data.Contract_Expiration_Date__c,
      data.Amount,
    ]
  );

  function handleDownloadExcel() {
    downloadExcel({
      fileName: 'Excel Expired contracts',
      sheet: 'Expired contracts',
      tablePayload: {
        header,
        body: body,
      },
    });
  }

  function filterAndSortArray(data, sortByProperty, sortDirection) {
    // Check if sortDirection is valid
    if (sortDirection !== 'ascending' && sortDirection !== 'descending') {
      throw new Error(
        'Invalid sortDirection. Use "ascending" or "descending".'
      );
    }

    // Clone the data array to avoid modifying the original array
    const clonedData = [...data];

    // Sort the array based on the sortByProperty
    clonedData.sort((a, b) => {
      const aValue = a[sortByProperty];
      const bValue = b[sortByProperty];

      // Compare the values based on the sortDirection
      if (sortDirection === 'ascending') {
        if (aValue < bValue) return -1;
        if (aValue > bValue) return 1;
        return 0;
      } else {
        if (aValue < bValue) return 1;
        if (aValue > bValue) return -1;
        return 0;
      }
    });

    return clonedData;
  }

  return (
    <div>
      <div className=''>
        <div className='flex justify-between bg-[#3D82F1] text-white p-5 h-[72px] 2xl:h-[76px] 2xl:pl-[40px] 2xl:pr-[26px] rounded-2xl m-3 lg:m-7'>
          <div className='flex items-center'>
            <h1 className='text-2xl 2xl:text-3xl font-semibold ml-2 md:ml-4 2xl:ml-0'>
              Expired contracts
            </h1>
          </div>
          <div className='hidden md:flex space-x-4 mr-3 2xl:mr-[9px] 2xl:ml-0 '>
            <div className='flex'>
              <input
                type='text'
                id='website-admin'
                className='rounded-md relative pr-6 left-8 pl-3 bg-gray-50 border  text-gray-900 focus:ring-mainblue focus:border-mainblue block flex-1 min-w-0 w-full text-sm border-gray-300'
                placeholder='Search'
                onChange={(e) => setQuery(e.target.value)}
              />
              <span className='inline-flex relative right-2  items-center px-2 text-gray-900 bg-gray-[#DBE3EB] rounded-l-md  border-r-0'>
                <img alt='search' src={Search} />
              </span>
            </div>
            <button
              onClick={handleDownloadExcel}
              className='text-[#292929] text-sm xl:text-base hidden md:flex items-center bg-yellow-gradient btn rounded-lg p-2 ml-6'
            >
              <Export className='mr-1 w-4  xl:w-5 xl:h-4' fill='#292929' />
              Download
            </button>
          </div>
        </div>
      </div>
      {newArray <= 0 && !callLoading ? (
        <>
          <div className='hidden md:grid grid-cols-24 mt-4 mx-8   max-h-[calc(100vh-250px)] 3xl:max-h-[650px] overflow-y-auto 2xl:mx-[55px] pt-4 lg:pt-6'>
            <div className=' bg-[#292929] text-white col-span-6 rounded-l-lg pl-4  text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              Company
            </div>
            <div className='bg-[#292929] text-white  col-span-6 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              Actual plan
            </div>
            <div className='bg-[#292929] text-white  col-span-4 text-sm  xl:text-lg font-semibold py-2 sticky top-0'>
              Service
            </div>
            <div className='bg-[#292929] text-white col-span-4 text-sm  xl:text-lg font-semibold py-2 sticky top-0 z-[9]'>
              Expiration Date
            </div>
            <div className=' bg-[#292929] text-white  text-sm  xl:text-lg col-span-3 font-semibold py-2 sticky top-0'>
              Amount
            </div>
            <div className='bg-[#292929] text-white rounded-r-lg col-span-1  text-sm  xl:text-lg font-semibold py-2 sticky top-0'></div>
          </div>
          <div className='flex'>
            <div className='mx-7 md:mx-9 2xl:mx-14 bg-[#E6F1FA] flex p-2 mt-4 text-[#3D82F1]  text-sm  xl:text-base'>
              <img className='mr-2' src={BlueInfo}></img> Your expired contracts
              after Partners Portal release (June ’23) will be shown in this
              section.
            </div>
          </div>
        </>
      ) : (
        <>
          <div className=''>
            <MyCustomersTable
              loading={callLoading}
              setSort={setSort}
              setOrientation={setOrientation}
              data={filterAndSortArray(search2(newArray), sort, orientation)}
            />
          </div>
          <MobileMyCustomers customers={newArray} />
        </>
      )}
    </div>
  );
};

export default ExpiredCustomers;
